import React, { useEffect, useState } from "react"
import Layout from "../../../components/layout"
import Remarks from "../../../components/remarks"
import ProfileCard from "../../../components/profileCard"
import "../../../styles/participantPage.css"
import Axios from "axios"
import baseUrl from "../../../components/baseUrl"
import Confirm from "../../../components/confirm"

function Participant(props) {
  const [info, setInfo] = useState({
    name: "",
    remarks: [],
  })
  const [remark, setRemark] = useState("")
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    Axios.get(`${baseUrl}/customer?id=${props.params.id}`).then(jsonRes => {
      setInfo(jsonRes.data)
    })
  }, [info])

  const handleAddRemark = e => {
    e.preventDefault()

    let insName=""
    let dateObj = new Date()
    var month = dateObj.getUTCMonth() + 1 //months from 1-12
    var day = dateObj.getUTCDate()
    var year = dateObj.getUTCFullYear()
    let newDate = day + "/" + month + "/" + year
    if ( typeof window !== "undefined"){
      insName=window.localStorage.getItem("user")
     }
    let newRemark = {
      date: newDate,
      instructor: insName,
      remark: remark,
    }

    Axios.post(`${baseUrl}/customer`, {
      post_id: props.params.id,
      post_remark: newRemark,
    })
    setInfo({ name: "", remarks: [] })
    setShowModal(false)
  }

  return (
    <>
      <Layout route="/customers/participant">
        <div className="participantPage mt-5">
          <h2>PROFILE</h2>
          <div>
            {info.name === "" ? (
              <div>Loading..</div>
            ) : (
              <ProfileCard info={info} />
            )}
          </div>
          <hr />
          <div className="grid grid-cols-12 mt-8">
            <div className="col-span-9 col-start-2">
              <h5 className="uppercase">Remarks</h5>
              <div></div>
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-10 col-start-2 md:col-span-9 md:col-start-2">
              <form class="w-full mt-4">
                <div class="flex flex-wrap -mx-3  mb-3">
                  <div class="w-full  px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
                      
                    >
                      ENTER REMARKS
                    </label>
                    <textarea
                      class="appearance-none block w-full h-[10vh] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-zip"
                      onChange={e => {
                        setRemark(e.target.value)
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-start p-6 md:p-6  border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-semibold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        if (remark === "") {
                          alert("Cannot submit empty report!")
                        } else {
                          setShowModal(true)
                        }
                      }}
                    >
                      ADD REMARK
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-9">
            {info.remarks.length === 0 ? (
              <div className="flex justify-center mb-9">No remarks added</div>
            ) : (
              <Remarks info={info.remarks} />
            )}
          </div>
        </div>
        {showModal ? (
          <Confirm
            title="Add remark?"
            task={handleAddRemark}
            setShowModal={setShowModal}
          />
        ) : null}
      </Layout>
    </>
  )
}

export default Participant
