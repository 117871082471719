import React from "react"

function Remarks({ info }) {


  return (
    <>
    
     
      {info.map(remark => {
        return (
          <div key={remark._id} className="grid grid-cols-12">
            <div className="col-span-10 col-start-2 md:col-span-9 md:col-start-2">
              <div class=" w-full py-4 px-8 bg-white shadow-lg rounded-lg my-3 md:my-6">
                <div>
                  <p class="mt-2 text-gray-600">{remark.remark}</p>
                </div>
                <div class="flex justify-end mt-4">
                  <p class=" font-medium text-teal-800">{remark.instructor}</p>
                </div>
                <div class="flex justify-end mt-1">
                  <p class="text-sm font-medium text-teal-800">{remark.date}</p>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
        )
      })}
    </>
  )
}

export default Remarks
