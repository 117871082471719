import React from "react"
import "../styles/profileCard.css"


function ProfileCard(props) {
  return (
    <div>
      <div className="info mt-9 grid grid-rows-3">
        {/* row1 */}
        <div className="grid grid-cols-12">
          <div className="col-span-10 col-start-2 md:col-span-4 md:col-start-2">
            <h6 className="uppercase">Name</h6>
            <div className="infoPiece">{props.info.name}</div>
          </div>
          <div className="col-span-10 col-start-2 md:col-span-4 md:col-start-7">
            <h6 className="uppercase">Course</h6>
            <div className="infoPiece">{props.info.course}</div>
          </div>
        </div>
        {/* row 2 */}
        <div className="grid grid-cols-12">
          <div className="col-span-10 col-start-2 md:col-span-4 md:col-start-2">
            <h6 className="uppercase">Time slot</h6>
            <div className="infoPiece">{props.info.time}</div>
          </div>
          <div className="col-span-4 col-start-2 md:col-span-2 md:col-start-7">
            <h6 className="uppercase">Gender</h6>
            <div className="infoPiece">{props.info.gender}</div>
          </div>
          <div className="col-span-4 col-start-7 md:col-span-1 md:col-start-10">
            <h6 className="uppercase">Age</h6>
            <div className="infoPiece">{props.info.age}</div>
          </div>
        </div>
        {/* row 3 */}
        <div className="grid grid-cols-12">
          <div className="col-span-10 col-start-2 md:col-span-4 md:col-start-2">
            <h6 className="uppercase">Contact 1</h6>
            <div className="infoPiece">{props.info.contact1}</div>
          </div>
          <div className="col-span-10 col-start-2 md:col-span-4 md:col-start-7">
            <h6 className="uppercase">Contact 2</h6>
            <div className="infoPiece">{props.info.contact2}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileCard
